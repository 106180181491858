<template>
  <div class="bg_light">
    <div class="item" :class="{ border_bottom: borderBottom }" @click="goToRoute">
      <div class="d_flex justify_content_between">
        <div class="d_flex">
          <div class="icon icon_medium" style="margin-right: 0.35rem" v-if="imageUrl">
            <img :src="imageUrl" />
          </div>
          <div class="d_flex justify_center">
            <div class="title d_flex justify_center">
              <span>{{ title }}</span>
              <span>
                <slot />
              </span>
            </div>
            <slot name="subTitle" class="sub_title" />
          </div>
        </div>
        <div class="sub_title">
          <slot name="sub_title"> </slot>
        </div>
        <template v-if="showIcon">
          <div class="icon" v-if="isShowArrow">
            <img src="../../../assets/images/public/ic_mine_return.webp" />
          </div>
          <div class="icon_toggle" v-else @click="toggleVoice">
            <img src="../../../assets/images/public/ic_com_off.png" v-if="turnOn" />
            <img src="../../../assets/images/public/ic_com_on.png" v-else />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
    isShowArrow: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      turnOn: false,
    };
  },
  methods: {
    goToRoute() {
      if (this.path === "") return;
      this.$emit("goToRoute", this.path);
    },
    toggleVoice() {
      this.turnOn = !this.turnOn;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg_light {
  background: white;
  padding: 0rem 1.2rem 0rem 1.2rem;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0 0;
}

.justify_content_between {

  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0rem;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &_medium {
    width: 1.5rem;
    transform: translateY(0rem);
  }
}

.icon_toggle {
  display: block;
  width: 2.5rem;
  // position: absolute;
  // right: 1.5rem;
  transform: translateY(2px);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.sub_title {
  // padding-left: 0.25rem;
  color: var(--gray);
  font-size: 0.85rem;
}

.justify_center {
  width: auto;
  justify-content: flex-start;
}
</style>
