<template>
  <Container theme="dark" :padding="false">
    <MsgItem v-for="(item, j) in list" :key="item.title" :title="item.title" :borderBottom="j < list.length - 1" :path="item.path" :isShowArrow="item.isShowArrow" @goToRoute="goToRoute">
      <div slot="sub_title" v-if="item.version !== ''">
        {{ item.version }}
      </div>
    </MsgItem>
    <div class="btn_default btn_primary" @click="logoutHandler">退出登录</div>
  </Container>
</template>

<script>
import { mapActions } from "vuex";
import { Message } from "element-ui";
import Container from "../components/Container";
import MsgItem from "../components/MsgItem.vue";
import tim from "@/utils/tim";

export default {
  components: {
    Container,
    MsgItem,
  },
  data() {
    return {
      isLoading: false,
      platform: "",
      list: [
        {
          title: "登录历史",
          path: "/my/setting/loginLogs",
          isShowArrow: true,
        },
        {
          title: "语音播报",
          path: "",
          isShowArrow: false,
        },
        {
          title: "意见反馈",
          path: "/my/setting/problem",
          isShowArrow: true,
        },
        {
          title: "版本号",
          path: "version",
          isShowArrow: true,
          version: "v 1.1.1",
        },
      ],
    };
  },
  methods: {
    ...mapActions("config", ["getUserAndroidVersion"]),
    ...mapActions("config", ["getUserIOSVersion"]),
    ...mapActions("auth", ["logout"]),
    goToRoute(path) {
      if (path === "version") {
        this.checkOSversion();
      } else {
        this.$router.push({ path: path });
      }
    },
    getPlatform() {
      let platform = ["Win32", "Android", "iOS"];
      for (let i = 0; i < platform.length; i++) {
        if (navigator.platform.indexOf(platform[i]) > -1) {
          if (platform[i] === "Win32") {
            this.platform = 0;
          } else {
            this.platform = i - 1;
          }
        }
      }
    },
    checkOSversion() {
      this.platform === 0 ? this.getUserAndroidVersion() : this.getUserIOSVersion();

      Message({
        message: "已经是最新版本了",
        iconClass: "x",
        center: true,
        customClass: "error_message",
      });
    },
    logoutHandler() {
      this.logout();
      tim.logout();
      this.$router.push({ path: "/login" });
    },
  },
  mounted() {
    this.getPlatform();
  },
};
</script>

<style lang="scss" scoped>
.btn_default {
  max-width: 367px;
  margin: 0 auto;
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    position: absolute;
    bottom: 10%;
    left: 5%;
    right: 5%;
  }

  &_primary {
    max-width: 353px;
    margin: 0 auto;
    color: white;
    background-color: var(--orange);
  }
}
</style>
